import React from 'react';
import { Link } from 'react-router-dom';
const PrivacyPolicy = () => {
    return (
        <div className='py-3'>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-custom-bg">
                <div className="container">
                    <Link className="navbar-brand" to="javascript:void(0)">
                        <img src={require('../assets/Main-Logo.png')} alt="Logo" className="header_logo img-fluid" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mynavbar">
                        <ul className="navbar-nav me-auto">
                            {/* <li><Link to="javascript:void(0)" className="nav-link px-2 text-white">Home</Link></li>
                  <li><Link to="javascript:void(0)" className="nav-link px-2 text-white">About</Link></li>
                  <li><Link to="javascript:void(0)" className="nav-link px-2 text-white">Services</Link></li>
                  <li><Link to="javascript:void(0)avascript:void(0)" className="nav-link px-2 text-white">Contact</Link></li> */}
                        </ul>
                        <form className="d-flex py-2 ps-2 py-lg-0 ps-lg-0">
                            <button className="btn btn-danger text_nowrap" type="button"><i className="bi bi-telephone"></i> Enquiry</button>
                        </form>
                    </div>
                </div>
            </nav>
            <div className="container py-4">
            <div className=''>
            <h1 className="mb-4">Privacy Policy</h1>

            <p>
                This Privacy Policy outlines how we handle your personal data and privacy rights.
                By using our services, you agree to the collection, use, and sharing of your data as described in this Privacy Policy.
            </p>

            <h2>1. Information We Collect</h2>
            <p>
                We collect information to provide better services to our users. This includes information you provide directly to us, 
                information collected automatically, and information obtained from third-party sources.
            </p>

            <h2>2. How We Use Your Information</h2>
            <p>
                We use the information we collect to operate, maintain, and improve our services, 
                provide customer support, communicate with you, and comply with legal obligations.
            </p>

            <h2>3. Sharing Your Information</h2>
            <p>
                We do not share your personal information with third parties except as necessary to provide our services, 
                comply with the law, or protect our rights.
            </p>

            <h2>4. Your Privacy Rights</h2>
            <p>
                You have the right to access, correct, or delete your personal information. You can also object to certain processing activities.
                If you have any questions about your privacy rights, please contact us.
            </p>

            <h2>5. Changes to This Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.
                We encourage you to review this Privacy Policy periodically for any changes.
            </p>

            <h2>6. Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy, please contact us at admin@scentiqo.app.
            </p>
            </div>
        </div>
        </div>
        
    );
}

export default PrivacyPolicy;
