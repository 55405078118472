import React from 'react'
import '../App.scss';
import { Link } from 'react-router-dom';
const Home = () => {
    return (
        <div className='homepage'>
            {/* Header Section Code */}
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-custom-bg">
                <div className="container">
                    <Link className="navbar-brand" to="javascript:void(0)">
                        <img src={require('../assets/Main-Logo.png')} alt="Logo" className="header_logo img-fluid" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mynavbar">
                        <ul className="navbar-nav me-auto">
                            {/* <li><Link to="javascript:void(0)" className="nav-link px-2 text-white">Home</Link></li>
                  <li><Link to="javascript:void(0)" className="nav-link px-2 text-white">About</Link></li>
                  <li><Link to="javascript:void(0)" className="nav-link px-2 text-white">Services</Link></li>
                  <li><Link to="javascript:void(0)avascript:void(0)" className="nav-link px-2 text-white">Contact</Link></li> */}
                        </ul>
                        <form className="d-flex py-2 ps-2 py-lg-0 ps-lg-0">
                            <button className="btn btn-danger text_nowrap" type="button"><i className="bi bi-telephone"></i> Enquiry</button>
                        </form>
                    </div>
                </div>
            </nav>

            {/* Hero Slideshow */}
            <div id="demo" className="carousel slide" data-bs-ride="carousel">

                {/* Indicators/dots */}
                {/* <div className="carousel-indicators">
              <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
              <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
              <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
            </div> */}

                {/* The slideshow/carousel */}
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="hero-banner">
                            <img src={require('../assets/Banner2.jpg')} alt="Banner" className="w-100 img-fluid" />
                            <div className="banner-text">
                                <div className="container">
                                    <div className="caption text-center">
                                        <h1>Welcome To Scentiqo</h1>
                                        <Link to="javascript:void(0)" className="btn btn-danger btn-lg text-uppercase custom-btn align-items-center mt-3 mb-3">Coming soon <i className="bi bi-chevron-right ms-2"></i></Link>
                                    </div>

                                    <div className="download-app text-center mt-4">
                                        <Link to="javascript:void(0)" className="mx-1">
                                            <img src={require('../assets/Android.png')} alt="App Icon" className="app-icon" />
                                        </Link>
                                        <Link to="javascript:void(0)" className="mx-1">
                                            <img src={require('../assets/IOS.png')} alt="App Icon" className="app-icon" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="carousel-item">
                <img src={require('./assets/chicago.jpg')} alt="Chicago" className="w-100 img-fluid" />
              </div>
              <div className="carousel-item">
                <img src={require('./assets/ny.jpg')} alt="New York" className="w-100 img-fluid" />
              </div> */}
                </div>

                {/* Left and right controls/icons */}
                {/* <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
              <span className="carousel-control-prev-icon"></span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
              <span className="carousel-control-next-icon"></span>
            </button> */}
            </div>

            {/* About Section */}
            {/* <section className="about">
            <div className="container ">
              <div className="row">
                <div className="col-md-7 d-flex justify-content-center">
                  <div className="txtdivabout ">
                    <h2 className="section-main-heading">About our story</h2>
                    <p className="fw-bold">In Italian Liberta means Freedom.<br /> We want our clients to have the freedom to get their
                      favorite foods from their favorite places quickly and easily.</p>
                    <div className="mt-4">
                      <h3>Our Philosophy is simple.</h3>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <div><img src={require('./assets/roots.png')} alt="" className='img-fluid' /></div>
                      <p className="m-0">1: Give Our Customers The Largest Variety Of Restaurants To Order From.</p>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <div><img src={require('./assets/roots.png')} alt="" className='img-fluid' /></div>
                      <p className="m-0">2: Our Customers Satisfaction Is And Will Always Be Our #1 Priority.</p>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <div><img src={require('./assets/roots.png')} alt="" className='img-fluid' /></div>
                      <p className="m-0">3: It Is A Privilege For Us To Be Able To Deliver Happiness In The Form Of The
                        Amazing Food, Our Local Restaurants Provide.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 d-flex justify-content-center align-items-center mt-5 mt-md-0">
                  <img src={require('./assets/fastfood.png')} alt="" className='img-fluid fruitsimg' />
                </div>
              </div>
            </div>
          </section> */}

            {/* Our Story Section */}
            {/* <section className="whatisstory">
            <div className="container">
              <div className="d-flex justify-content-center align-items-center">
                <img src={require('./assets/perfume-bottle.png')} alt="" className='img-fluid' style={{width:'150px'}} />
              </div>
              <h3 className="section-main-heading d-flex justify-content-center align-items-center mt-2 mb-4">What is our story</h3>
              <p className="m-0 text-center">A group of guys in South Florida who have been successful in many different types of
                businesses got together and decided to check out what this restaurant delivery service was
                all about. They started a small delivery service that was part of a larger nationwide
                company and spent two years learning the business inside and out and started making plans to
                have their own cutting edge technology built so that they could one day build a company in
                honor of the father of one of their partners. We believe everything happens the way it is
                supposed to and this company is no exception. <br /><br />Giorgio Schiavone is one of our
                company
                founders and his father Giorgio not only shared the same name, but they also shared a love
                of great food. When we were working to create MyLiberta.com we wanted it based on service to
                our customers and our restaurant partners above everything. If you ever meet Giorgio II you
                will know he puts those around him first which he learned from his dad and is always willing
                to go the extra mile.<br /><br /> In honor of his father, we call all of our drivers Giorgio and
                we
                train them to always be friendly and deliver the best quality food as quick as possible. We
                can’t wait for you to experience our service and we promise to always treat you like family.
                (Well, at least treat you like the side of the family you like.)
              </p>
            </div>
          </section> */}

            {/* Footer Section */}
            <footer className="text-center text-lg-start text-white footer-bg">
                <section className="p-4 footer-social-strip">
                    <div className="container">
                        <div className="row mt-2 mb-2 align-items-center">
                            <div className="col-md-6 text-center text-md-start">
                                <p className="m-0 fs-5">Connected with us on social networks:</p>
                            </div>
                            <div className="col-md-6 text-center text-md-end mt-3 mt-md-0">
                                <Link to="javascript:void(0)" className="text-white me-4"><i className="bi bi-facebook"></i></Link>
                                <Link to="javascript:void(0)" className="text-white me-4"><i className="bi bi-twitter"></i></Link>
                                <Link to="javascript:void(0)" className="text-white me-4"><i className="bi bi-google"></i></Link>
                                <Link to="javascript:void(0)" className="text-white me-4"><i className="bi bi-instagram"></i></Link>
                                <Link to="javascript:void(0)" className="text-white me-4"><i className="bi bi-linkedin"></i></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mb-4">
                                <h6 className="text-uppercase fw-bold">Scentiqo</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto footer-title-line" />
                                <p>
                                    Scentiqo is all about for perfumes and its decant products.
                                </p>
                            </div>

                            <div className="col-md-2 col-lg-2 col-xl-3 mb-4">
                                <div className="ftr-menu-wrpr ms-xl-5">
                                    <h6 className="text-uppercase fw-bold">Products</h6>
                                    <hr className="mb-4 mt-0 d-inline-block mx-auto footer-title-line" />
                                    <p><Link to="javascript:void(0)" className="text-white">Designer Perfume</Link></p>
                                    <p><Link to="javascript:void(0)" className="text-white">Niche Perfume</Link></p>
                                    <p><Link to="javascript:void(0)" className="text-white">Perfume Oil</Link></p>
                                    <p><Link to="javascript:void(0)" className="text-white">Oriental Perfume</Link></p>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-2 col-xl-3 mb-4">
                                <div className="ftr-menu-wrpr ms-xl-4">
                                    <h6 className="text-uppercase fw-bold">Useful links</h6>
                                    <hr className="mb-4 mt-0 d-inline-block mx-auto footer-title-line" />
                                    <p><Link to="javascript:void(0)" className="text-white">Your Account</Link></p>
                                    <p><Link to="javascript:void(0)" className="text-white">Become an Affiliate</Link></p>
                                    <p><Link to="javascript:void(0)" className="text-white">Shipping Rates</Link></p>
                                    <p><Link to="javascript:void(0)" className="text-white">Help</Link></p>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-3 mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold">Contact</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto footer-title-line" />
                                <p><i className="bi bi-house-door"></i> Dofthörnan AB| SE559426919201
                                    Västergatan 17
                                    23164 Trelleborg,Sweden</p>
                                <p><i className="bi bi-envelope"></i> Admin@scentiqo.app</p>
                                {/* <p><i className="bi bi-telephone"></i> + 01 234 567 88</p>
                    <p><i className="bi bi-printer"></i> + 01 234 567 89</p> */}
                            </div>
                        </div>
                    </div>
                </section>

                <div className="text-center copyright-bg p-3">
                    &copy 2024 copyright:
                    <Link className="text-white" to="javascript:void(0)">scentiqo.app</Link>
                </div>

            </footer>
        </div>
    );
}

export default Home